<template>
  <b-container fluid>
    <b-card>
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group

              label="Data Inicial"
            >
              <b-form-input
                id="input-group-1"
                v-model="props.initialDate"
                type="date"
              />
            </b-form-group>

          </b-col>

          <b-col md="6">
            <b-form-group
              label="Data Final"
            >
              <b-form-input
                v-model="props.endDate"
                type="date"
              />
            </b-form-group>
          </b-col>

          <b-button
            variant="primary"
            class="mx-auto"
            @click="handleClickConsultButton"
          >
            Consultar
          </b-button>

        </b-row>

        <TaskController
          :params="props"
          :tasks="tasks"
          @execute-task="executeTask"
        />
      </b-form>
    </b-card>

    <Table
      :list="list"
    >
      <template #filters>

        <b-card v-if="props.initialDate && props.endDate">
          <Report
            :start-date="props.initialDate"
            :end-date="props.endDate"
          />
        </b-card>
        <TableFilters
          :groups-options="groupsOptions"
          :merchants-options="merchantsOptions"
          :props="props"
        />

      </template>
    </Table>

<b-card>

<ConciliationFile v-if="props.initialDate && props.endDate"
            :initialDate="props.initialDate"
            :finalDate="props.endDate"
/>
 </b-card>


  </b-container>
</template>

<script>
import {
    BContainer, BCard, BForm, BFormInput, BRow, BCol, BFormGroup, BButton,
} from 'bootstrap-vue';
import { props } from 'vue-prism-component';
import TaskService from '@/service/tasks';
import MerchantService from '@/service/merchant';

import Table from './Table.vue';
import TableFilters from './TableFilters.vue';

import TaskController from './TaskController.vue';
import ConciliationFile from './ConciliationFiles.vue'
import { getCurrentDate } from '@/utils/date/current';
import Report from './Report.vue';

export default {
    components: {
        BContainer, BCard, BForm, BFormInput, BRow, BCol, BFormGroup, Table, TaskController, TableFilters, BButton, Report,ConciliationFile
    },
    data() {
        return {
            data: { list: [] },
            groups: [],
            merchants: [],
            props: {
                initialDate: getCurrentDate(),
                endDate: getCurrentDate(),
                ec: [],
                group: [],
            },
            tasks: [
                { title: 'Atualizar Parcelas', path: '/update-installments' },
                { title: 'Atualizar Pagamentos', path: '/upload/updateInstallments' },
                { title: 'Reprocessar Transações', path: '/checkingTransactions ' },
                { title: 'Atualizar Liquidação Veripag', path: '/liquidation-veripag ' },
                { title: 'Atualizar Extrato Veripag', path: '/extract-veripag' },
                { title: 'Arquivos Equals', path: '/sftp-move-edi-equals ' },
                { title: 'Arquivos Vendas e Pagamentos', path: '/sftp-move-sales' },
                { title: 'Arquivo BoaVista', path: '/send-eextract' },
            ],
        };
    },

    computed: {
        groupsOptions() {
            return Object.entries(this.groups).filter(([_, item]) => item.length > 1).map(([key, item]) => ({
                value: [item],
                text: key,
            })).sort((a, b) => a.text.localeCompare(b.text));
        },

        merchantsOptions() {
            return this.merchants.map(item => ({
                value: [item.establishment_id],
                text: `${item.establishment_id} - ${item.name}`,
            }));
        },

        list() {
            const arr = [...this.props.ec, ...this.props.group];
            if (!arr.length) return this.data.list;
            const set = new Set(arr.map(item => item.value).flat(Infinity));
            return this.data.list.filter(item => set.has(item.ec));
        },
    },

    async mounted() {
        this.callLoading(true);
        await Promise.all([
            this.getData(),
            this.getMerchants(),
            this.getGroup(),
        ]);
        this.callLoading(false);
    },

    methods: {
        async getData() {
            const { data } = await TaskService.amounTransacted({
                endDate: this.props.endDate,
                initialDate: this.props.initialDate,
            });
            // console.log(endDate,initialDate);
            
            this.data = data;
        },

        async handleClickConsultButton() {
            this.callLoading(true);
            await this.getData();
            this.callLoading(false);
        },

        async executeTask(task) {
            const isConfirmed = await this.confirmAnAction(`Deseja realmente rodar a tarefa ${task.title}?`);

            if (!isConfirmed) return;

            this.callLoading(true);
            TaskService.executeTask({
                url: task.path,
                endDate: this.props.endDate,
                initialDate: this.props.initialDate,
            }).then(({ status }) => {
                if (status === 200) {
                    this.toast(`'${task.title}' Foi Concluído`, 'CheckIcon', 'success');
                } else {
                    this.toast(`Erro ao Executar ${task.title}`, 'XIcon', 'danger');
                }
            });
            this.modal(`'${task.title}' está sendo executado, você será notificado quando terminar`);
            this.callLoading(false);
        },

        async getMerchants() {
            const { data } = await MerchantService.getMerchantEstablishment();
            this.merchants = data;
        },

        async getGroup() {
            const { data } = await MerchantService.getMerchantGroup();
            this.groups = data;
        },
    },
};
</script>
