<template>
  <div>
      <!-- Título do componente -->
      <h2 class="text-center mb-4">Arquivos de Conciliação</h2>
  
      <!-- Select para alterar os dados exibidos -->
      <b-row class="mb-3">
          <b-col md="4">
              <b-form-group label="Selecione o tipo de dados">
                  <b-form-select v-model="selectedOption" :options="options"></b-form-select>
              </b-form-group>
          </b-col>
      </b-row>
  
      <!-- Tabela dinâmica responsiva -->
      <b-card>
          <div class="table-responsive">
              <template v-if="loading">
                  <p class="text-center">Carregando...</p>
              </template>
              <template v-else-if="tableData.length">
                  <b-table :items="paginatedData" :fields="tableFields" class="text-center">
                      <template v-for="field in tableFields" :slot="`cell(${field.key})`" slot-scope="data">
                          {{ data.item[field.key] }}
                      </template>
                  </b-table>
              </template>
              <p v-else class="text-center text-danger">Não há resultado para essa data.</p>
          </div>
  
          <!-- Paginação -->
          <b-pagination 
              v-if="tableData.length" 
              v-model="currentPage" 
              :total-rows="tableData.length" 
              :per-page="itemsPerPage" 
              align="center" 
              size="md" 
              class="mt-3">
          </b-pagination>
      </b-card>
  </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { BCard, BRow, BCol, BTable, BFormGroup, BFormSelect, BPagination } from 'bootstrap-vue';
  
  export default {
      components: { BCard, BRow, BCol, BTable, BFormGroup, BFormSelect, BPagination },
  
      props: {
          initialDate: { type: String, required: true },
          finalDate: { type: String, required: true },
      },
  
      data() {
          return {
              selectedOption: '',
              options: [
                  { value: 'showEquals', text: 'Equals' },
                  { value: 'showBoaVista', text: 'Boa Vista' },
              ],
              tableFields: [],
              tableData: [],
              currentPage: 1,
              itemsPerPage: 10,
              loading: false, // Indica se está carregando os dados
          };
      },
  
      computed: {
          paginatedData() {
              const startIndex = (this.currentPage - 1) * this.itemsPerPage;
              const endIndex = startIndex + this.itemsPerPage;
              return this.tableData.slice(startIndex, endIndex);
          },
      },
  
      methods: {
          async fetchData() {
              if (!this.selectedOption || !this.initialDate || !this.finalDate) return;
  
              this.loading = true; // Inicia o estado de carregamento
  
              try {
                  const BASE_URL = process.env.VUE_APP_BASE_URL_THOTH;
                  const API_KEY = process.env.VUE_APP_API_KEY_THOTH;
  
                  const response = await axios.get(`${BASE_URL}${this.selectedOption}`, {
                      params: { initialDate: this.initialDate, finalDate: this.finalDate },
                      headers: { 'Api-Key': API_KEY },
                  });
  
                  if (response.data && response.data.length > 0) {
                      this.processData(response.data);
                  } else {
                      this.clearData();
                  }
              } catch (error) {
                  console.error('Erro ao buscar os dados:', error);
                  this.clearData();
              } finally {
                  this.loading = false; // Finaliza o estado de carregamento
              }
          },
  
          processData(data) {
              this.tableFields = Object.keys(data[0]).map(key => ({
                  key,
                  label: key.charAt(0).toUpperCase() + key.slice(1),
              }));
  
              this.tableData = data.map(row => {
                  const newRow = { ...row };
                  if ('Data do Arquivo' in newRow) {
                      newRow['Data do Arquivo'] = this.formatDate(newRow['Data do Arquivo']);
                  }
                  return newRow;
              });
          },
  
          clearData() {
              this.tableFields = [];
              this.tableData = [];
          },
  
          formatDate(value) {
              if (!value) return '';
              try {
                  const date = new Date(value);
                  if (isNaN(date.getTime())) return value;
  
                  const day = String(date.getUTCDate()).padStart(2, '0');
                  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
                  const year = date.getUTCFullYear();
                  return `${day}/${month}/${year}`;
              } catch (error) {
                  console.error('Erro ao formatar data:', error);
                  return value;
              }
          },
  
          resetState() {
              this.currentPage = 1; // Reseta a página para a primeira
              this.clearData(); // Limpa os dados
              this.fetchData(); // Realiza a consulta novamente
          },
      },
  
      watch: {
          selectedOption: {
              immediate: true,
              handler() {
                  this.resetState();
              },
          },
          initialDate: {
              immediate: true,
              handler() {
                  this.resetState();
              },
          },
          finalDate: {
              immediate: true,
              handler() {
                  this.resetState();
              },
          },
      },
  
      beforeMount() {
          this.selectedOption = 'showEquals';
      },
  };
  </script>
  
  <style scoped>
  .mb-3 {
      margin-bottom: 1rem;
  }
  
  .text-center {
      text-align: center;
  }
  
  .text-danger {
      color: red;
  }
  
  .table-responsive {
      overflow-x: auto;
  }
  </style>
  